import './App.css';
import FLADMugImg from './Assets/FLAD GLOSSY mug trimmed.png';
import FLADMugColdCloseUp from './Assets/FLADCloseupInFocus.png';
import FLADMugCloseUpSolidGrey from './Assets/KRUS solid grey closeup.png';
import FLADMugSunset from "./Assets/Flat white bulk sunset scene.png";
import FLADMugCloseupMobile from './Assets/FLADClouseUpInFocusMobileScale.png'
import { IoChevronDownOutline } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";
import { useRef } from 'react';

import { useForm, ValidationError } from '@formspree/react';

/*
          <form className='signUpBody'>
              <p className='labelForEmailInput'>Sign up for the release
              </p>
              <input type="email" id='emailInput' placeholder='adress@email.com' className='emailInput' name='emailInput'></input>
              <button class="ml-onclick-form" onclick="ml('show', 'c92u0W', true)">Join KRUS</button>
          </form>

*/

/* ABOUT KRUS FOUNDER
          <p className='staticBodyText'>
            <h2 style={{marginBottom: 35}}>
              By the barista
              <br />
                <span style={{marginLeft: '4vw', position: 'absolute', color: 'lightgray', lineHeight: '4vh'}}>for the community</span>
            </h2>

            KRUS is founded by a former coffee shop manager located in Copenhagen.
            <br/>
              <span style={{fontWeight: 500, color: 'lightgray'}}>
                "It's not the bean,
                it's everything around the bean."
              </span>
            <br/>
            
            Working as a barista, I realized the attention was put into the cup, not around the cup. Through KRUS I want to embrace the beautiful and timeless aspect of coffee - everything around the beans:
            The community, the senses, the smiles and most importantly the mugs.
          </p>
*/





function ContactForm() {
  const [state, handleSubmit] = useForm("xleqkypk");
  if (state.succeeded) {
      return <p className='ThankYou'>
      <span style={{fontSize: 100}}>Thank you!</span>
      <br />
      We appreciate your interest in KRUS.
      <br />
      We'll keep you up to date with new arrivals and updates around KRUS.
      </p>
  }else{
    return (
      <form className='signUpBody' onSubmit={handleSubmit}>
        <label className='labelForEmailInput' htmlFor="email">
          Join our launch list and be a part of the journey:
        </label>
          <div className='userInputField'>
              <input className='emailInput'
                id="email"
                type="email" 
                name="email"
                placeholder='address@email.com'
              />
              <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
              />
              <button type="submit" disabled={state.submitting}>
                <IoArrowForwardCircle style={{verticalAlign: 'middle'}}/>
              </button>

          </div>
          <label>*By signing up, you agree to recieve emails including news & updates about KRUS*</label>
      </form>
    );
  }
}


function App() {

  const bottomPageView = useRef(HTMLElement);
  const TopPageView = useRef(HTMLElement); 
  const socialsPageView = useRef(HTMLElement);

  return (
    <div className="App">


      <div className='topPage' ref={TopPageView}>
        <header>KRUS</header>
        <h1 id='h1TopPage' style={{textAlign: 'left', color: '#2e1200', position: 'relative', zIndex: 1, marginLeft: '10vw'}}>Beyond the beans</h1>

        <div className='centerBody'>
          <img src={FLADMugColdCloseUp} alt='FLADMugColdCloseUp' className='topBrandMugImage'></img>
          <ContactForm/>
          <img src={FLADMugCloseupMobile} alt='FLADMugCloseupMobile' id='topBrandImageMobile'></img>
        </div>

        <div className='scrollDownFill'>
          
          <IoChevronDownOutline className='scrollButton' onClick={() => bottomPageView.current?.scrollIntoView({behavior: 'smooth'})} size={45}/>
        </div>
      </div>

      <div className='bottomPage' ref={bottomPageView}>

          <h1 className='h1BottomPage'>Beyond the beans</h1>

          <div className='bottomPageBodyArticle'>
          
          <div className='aboutColumn'>
            <img id='FLADMugSunset' src={FLADMugSunset} style={{width: '60vw', position: 'relative'}} alt='FLADMugSunset'></img>
            <p className='staticBodyText'>
              <h2>The sense</h2>
              KRUS is all about capturing the timeless moments.
              Appreciating the surroundings that creates the joy.
              The smiles, conversations, the senses:
              It all starts with the mug.
              <br />
              <br />
              
              <br />
              <br />
              We strive to go beyond the bean.
            </p>
          </div>
          
          <div className='aboutColumn' id='aboutColumnReversed'>
          <img id='FLADMugCloseUpSolidGrey' src={FLADMugCloseUpSolidGrey} style={{width: '60vw', position: 'relative'}} alt='FLADMugCloseUpSolidGrey'></img>
            <p className='staticBodyText'>
              <h2>Find us</h2>
              Our mugs are not in stock as of right now,
              but they are just around the corner. In the meantime you can <a style={{fontWeight: '400', fontStyle: 'italic'}} onClick={() => socialsPageView.current?.scrollIntoView({behavior: 'smooth'})}>follow our socials</a>,
              <br/>
              and <a style={{fontWeight: '400', fontStyle: 'italic'}} onClick={() => TopPageView.current?.scrollIntoView({behavior: 'smooth'})}>sign up for the launch list</a> of our first mug.
              <br/>
              We will happily keep you updated.
              <br/>
              <br />
              - Best Regards, KRUS
              <br/>
              <br />
            </p>
          </div>
              <div style={{width:'100%', textAlign:'center', color:'#fff'}}>
                <IoChevronUpOutline className='scrollButton' onClick={() => TopPageView.current?.scrollIntoView({behavior: 'smooth'})} style={{verticalAlign: 'middle'}} size={45}/>
              </div>
        </div>

        <footer ref={socialsPageView}>
          <h1 style={{marginBottom: '5px', marginTop: '50px', color: '#474747'}}>Socials</h1>
          <a href='https://www.instagram.com/kruscph/'><FaInstagram size={55} className='footerIcon'/></a>
          <a href='https://www.tiktok.com/@kruscph'><AiFillTikTok size={55} className='footerIcon'/></a>
        </footer>
      </div>
    
    </div>
  );
}

export default App;
